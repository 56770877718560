import React from "react"
import Layout from "../layouts/Default"

const NotFoundPage = (): JSX.Element => (
  <Layout className="Uppercase">
    <h1>Not found</h1>
    <p>We are truly sorry but this page does not exist.</p>
  </Layout>
);

export default NotFoundPage
